@tailwind base;
@tailwind components;
@tailwind utilities;

/* Estilos para el scrollbar */
::-webkit-scrollbar {
    width: 10px;
  }
  
  ::-webkit-scrollbar-track {
    background: #1a202c;
    border-radius: 5px;
  }
  
  ::-webkit-scrollbar-thumb {
    background: #4a5568;
    border-radius: 5px;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #718096;
  }

  .html-content-wrapper {
    width: 100%;
    overflow-x: auto;
    overflow-y: visible;
    padding: 1rem;
  }
  
  .html-content-wrapper table {
    margin: 0 auto;
    max-width: 100% !important;
    width: auto !important;
  }
  
  .html-content-wrapper img {
    max-width: 100%;
    height: auto;
  }
  
  @media (max-width: 640px) {
    .html-content-wrapper {
      font-size: 12px;
    }
    
    .html-content-wrapper table {
      width: 100% !important;
    }
  }